import React from 'react';
import {
  Skeleton as MuiSkeleton,
} from '@mui/material';
import styles from './OrderListSkeleton.module.css';

function OrderListSkeleton() {
  return (
    <div className={styles.skeletonContainer}>
      <div className={styles.skeletonList}>
        {[1, 2, 3].map((index) => (
          <div key={index} className={styles.skeletonCard}>
            <div className={styles.textItem}>
              <MuiSkeleton variant="text" width={150} sx={{ fontSize: '1.4rem', padding: '10px' }} />
            </div>
            <div className={styles.cardItems}>
              {[1, 2].map((index) => (
                <MuiSkeleton key={index} variant="rounded" width={100} height={100} style={{ marginBottom: '20px' }} />
              ))}
            </div>
            <div className={styles.textItem}>
              <MuiSkeleton variant="text" width={50} sx={{ fontSize: '1.2rem' }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderListSkeleton;
