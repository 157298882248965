import React, { useEffect } from 'react';
import {
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrders, selectOrders, getOrdersStatus } from '../../redux/ordersSlice';
import OrderCard from './OrderCard';
import OrderListSkeleton from './OrderListSkeleton';
import styles from './OrderList.module.css';
import useQueryParams from '../../hooks/useQueryParams';

function OrderList() {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrders);
  const ordersStatus = useSelector(getOrdersStatus);
  const { shopId } = useQueryParams();

  useEffect(() => {
    if (ordersStatus === 'idle') {
      dispatch(fetchOrders(shopId));
    }
  }, [ordersStatus, dispatch, shopId]);

  if (ordersStatus === 'loading') {
    return <OrderListSkeleton />;
  }

  if (!orders || !orders.length) {
    return (
      <div className={styles.orderDetailsContainer}>
        <Typography variant="h6" sx={{ margin: '0px', padding: '150px 0px', color: 'var(--tg-theme-destructive-text-color)' }}>Orders not found</Typography>
      </div>
    );
  }

  return (
    <div className={styles.orderListContainer}>
      <div className={styles.orderList}>
        {orders.map((order) => (
          <OrderCard key={order.id} order={order} />
        ))}
      </div>
    </div>
  );
}

export default OrderList;
