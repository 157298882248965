import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import { useNavigate } from "react-router-dom";
import styles from "./BottomNavigationMenu.module.css";
import useQueryParams from '../../hooks/useQueryParams';

const BottomNavigationMenu = (props) => {
  const navigate = useNavigate();
   const { shopId } = useQueryParams();

  const navigationItems = [
    {
      icon: <ManageSearchRoundedIcon />,
      url: shopId ? `/products?shopId=${shopId}` : `/products`,
    },
    {
      icon: <TimelineRoundedIcon />,
      url: shopId ? `/orders?shopId=${shopId}` : "/orders",
    }
  ];

    return (
        <Paper
          className={styles.paper}
          elevation={3}
        >
        <BottomNavigation
          showLabels
          className={styles.menu}
          // value={value}
          onChange={(event, navigationUrl) => {
            navigate(navigationUrl);
          }}
        >
          {
            navigationItems.map((item, index) => (
              <BottomNavigationAction
                sx={{
                  padding: "0px 12px 28px"
                }}
                key={item.url}
                className={styles.action}
                icon={item.icon}
                value={item.url}
              />
            ))
          }
        </BottomNavigation>
      </Paper>
    );
}

export default BottomNavigationMenu;