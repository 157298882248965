import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, AppBar, Toolbar, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteForeverRounded";
import SendIcon from "@mui/icons-material/SendRounded";
import {
  selectCurrentProduct,
  getCurrentProductStatus,
  fetchProduct,
  deleteProduct,
  clear,
} from "../../redux/productSlice";
import { clearProducts } from "../../redux/productsSlice";
import { postProductToChannel } from "../../redux/channelSlice";
import { noImageURL } from "../../api/config";
import About from "./About";
import Characteristics from "./Characteristics";
import ProductDetailsSkeleton from "./ProductDetailsSkeleton";
import styles from "./ProductDetails.module.css";
import useQueryParams from "../../hooks/useQueryParams";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

function ProductDetails() {
  const { id } = useParams();
  const product = useSelector(selectCurrentProduct);
  const status = useSelector(getCurrentProductStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopId } = useQueryParams();

  const productsPath = shopId ? `/products?shopId=${shopId}` : `/products`;

  const navigateToProducts = () => {
    navigate(productsPath);
  };

  const handleDeleteProductClick = async () => {
    const result = await dispatch(deleteProduct(id));
    if (deleteProduct.fulfilled.match(result)) {
      await dispatch(clearProducts());
      navigateToProducts();
    }
  };

  const handlePostToChannelClick = async () => {
    const result = await dispatch(postProductToChannel(id));
    if (postProductToChannel.fulfilled.match(result)) {
      navigateToProducts();
    }
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchProduct(id));
    }
  }, [status, id, dispatch]);

  useEffect(
    () => () => {
      dispatch(clear());
    },
    [dispatch, id]
  );

  if (status === "loading") {
    return <ProductDetailsSkeleton />;
  }

  if (!product) {
    return (
      <div className={styles.productDetailsContainer}>
        <Typography
          variant="h6"
          sx={{
            margin: "0px",
            padding: "150px 0px",
            color: "var(--tg-theme-destructive-text-color)",
          }}
        >
          Product not found
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.productDetailsContainer}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "var(--tg-theme-section-bg-color)",
          boxShadow: "none",
          height: "56px",
        }}
      >
        <Toolbar sx={{ height: "56px" }}>
          <Typography variant="body1" className={styles.headerText}>
            {product.title}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          {/* Add flexGrow to push next icon to the right */}
        </Toolbar>
      </AppBar>
      <div className={styles.productDetails}>
        <div className={styles.productImageContainer}>
          <Swiper pagination={true} modules={[Pagination]}>
            <SwiperSlide>
              <img src={product.mainMediaUrl || noImageURL} alt={product.title} />
            </SwiperSlide>

            {product.mediaFiles.map((mediaFile, index) => (
              <SwiperSlide key={index}>
                <img
                  src={mediaFile.fileUrl || noImageURL}
                  alt={mediaFile.fileUrl}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Typography variant="h5" className={styles.productName}>
          {product.title}
        </Typography>
        <Typography variant="body1" className={styles.productDescription}>
          {product.summary}
        </Typography>
        <Typography variant="body1" className={styles.productDescription}>
          {`${product.price} ${product.currencySymbol}`}
        </Typography>
        {/* <About product={product} /> */}
        {/* <Characteristics product={product} /> */}
        {product.description &&
          Object.keys(product.description).length !== 0 && (
            <About product={product} />
          )}
        {product.attributes && Object.keys(product.attributes).length !== 0 && (
          <Characteristics product={product} />
        )}
      </div>
      <div className={styles.footer}>
        <Button
          variant="contained"
          disableElevation
          onClick={handleDeleteProductClick}
          startIcon={<DeleteIcon />}
          sx={{
            width: "45%",
            height: "50px",
            // backgroundColor: 'var(--tg-theme-button-color)',
            backgroundColor: "#b30c04",
            // background: '#FF0000',
            color: "var(--tg-theme-button-text-color)",
            borderRadius: "25px",
            marginLeft: "16px",
            textTransform: "none",
            // filter: 'brightness(0.7)',
          }}
        >
          <Typography>Delete Product</Typography>
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={handlePostToChannelClick}
          startIcon={<SendIcon />}
          sx={{
            width: "45%",
            height: "50px",
            backgroundColor: "var(--tg-theme-button-color)",
            color: "var(--tg-theme-button-text-color)",
            borderRadius: "25px",
            marginRight: "16px",
            textTransform: "none",
          }}
        >
          <Typography>Post to Channel</Typography>
        </Button>
      </div>
    </div>
  );
}

export default ProductDetails;
